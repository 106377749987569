<template>
  <div class="login-page">
    <!-- <loading :active.sync="isLoading" loader="dots"></loading> -->

    <div class="login-wrap">
      <div class="cover-wrap">
        <div class="cover-inner">
          <div class="sign-text-wrap">
            <div class="title">
              <LogoLight />
            </div>
            <div class="sub-title">
              歡迎回來<br />
              請輸入帳號、密碼登入系統<br />
            </div>
            <div class="sign-btn" @click="goHomePage">首頁</div>
          </div>
        </div>
      </div>
      <div class="login-form-wrap">
        <div class="login-form-inner">
          <form class="login-form" @submit.prevent="mangerSignin">
            <h4 class="form-title">管理者登入</h4>
            <label for="Email">帳號</label>
            <input
              type="text"
              id="Email"
              placeholder="請輸入帳號"
              required
              v-model="user.Email"
            />
            <label for="password">密碼</label>
            <input
              type="password"
              id="inputPassword"
              placeholder="請輸入密碼"
              required
              v-model="user.Password"
            />
            <button type="submit" class="login-btn">登入</button>
            <span
              style="display: block; float: right; cursor: pointer"
              @click="forgetPassword"
            >
              忘記密碼
            </span>
          </form>
        </div>
      </div>
    </div>

    <!-- 忘記密碼 Modal -->
    <div
      class="modal fade"
      id="forgetPasswordModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="forgetPasswordModalLabel"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <ValidationObserver v-slot="{ handleSubmit, reset }" ref="form">
            <form @reset.prevent="reset">
              <div class="modal-header">
                <span>忘記密碼</span>
              </div>
              <div class="modal-body">
                <div class="form-group">
                  <ValidationProvider
                    ref="forgetPassword"
                    rules="required|email"
                    name="註冊信箱"
                    v-slot="{ errors }"
                  >
                    <label>
                      <span class="mark">*</span>
                      註冊信箱
                    </label>
                    <input
                      type="text"
                      placeholder="請輸入註冊信箱"
                      v-model.trim="account"
                    />
                    <span
                      v-if="errors[0]"
                      class="text-danger"
                      style="font-size: 14px"
                    >
                      {{ errors[0] }}
                    </span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="reset"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  取消
                </button>
                <button
                  type="button"
                  class="btn btn-success"
                  @click="handleSubmit(sendCode)"
                >
                  送出
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>

    <!-- 變更密碼 Modal -->
    <div
      class="modal fade"
      id="changePasswordModal"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <ValidationObserver
            ref="changePassword"
            v-slot="{ handleSubmit, reset }"
          >
            <form @reset.prevent="reset">
              <div class="modal-header">
                <span>變更密碼</span>
              </div>
              <div class="modal-body">
                <div class="form-group">
                  <ValidationProvider
                    name="新密碼"
                    vid="confirmation"
                    rules="required|password_length:8"
                    v-slot="{ errors }"
                  >
                    <label>
                      <span class="mark">*</span>
                      新密碼<span class="mark">(長度須大於等於8)</span>
                    </label>
                    <input
                      type="password"
                      placeholder="請輸入新密碼"
                      v-model.trim="password"
                    />
                    <span
                      v-if="errors[0]"
                      class="text-danger"
                      style="font-size: 14px"
                    >
                      {{ errors[0] }}
                    </span>
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <ValidationProvider
                    name="確認新密碼"
                    rules="required|confirmed:confirmation"
                    v-slot="{ errors }"
                  >
                    <label>
                      <span class="mark">*</span>
                      確認新密碼
                    </label>
                    <input
                      type="password"
                      placeholder="請再次輸入密碼"
                      v-model.trim="confirmPassword"
                    />
                    <span
                      v-if="errors[0]"
                      class="text-danger"
                      style="font-size: 14px"
                    >
                      {{ errors[0] }}
                    </span>
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <ValidationProvider
                    name="驗證碼"
                    rules="required|alpha_num"
                    v-slot="{ errors }"
                  >
                    <label>
                      <span class="mark">*</span>
                      驗證碼<span class="mark">(已寄至您的信箱)</span>
                    </label>
                    <input
                      type="text"
                      placeholder="請輸入驗證碼"
                      v-model.trim="code"
                    />
                    <span
                      v-if="errors[0]"
                      class="text-danger"
                      style="font-size: 14px"
                    >
                      {{ errors[0] }}
                    </span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="reset"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  取消
                </button>
                <button
                  type="button"
                  class="btn btn-success"
                  @click="handleSubmit(changePassword)"
                >
                  確認
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* global $ */
import jwt_decode from "jwt-decode";

import LogoLight from "@/components/company/LogoLight.vue";

export default {
  name: "Login",

  components: {
    LogoLight,
  },
  data() {
    return {
      // 登入 data
      user: {
        Email: "",
        Password: "",
      },

      // 忘記密碼
      account: null, // 註冊信箱
      password: null, // 欲變更密碼
      confirmPassword: null, // 確認密碼
      code: null, // 驗證碼
    };
  },

  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    },
  },

  methods: {
    // 回首頁
    goHomePage() {
      this.$router.push({ name: "Login" });
    },

    // 管理者登入 API
    mangerSignin() {
      const vm = this;
      const api = `${window.BaseUrl.api}/auth/backstagelogin`;

      vm.$store.dispatch("updateLoading", true);

      vm.$http
        .post(api, vm.user)
        .then((response) => {
          if (response.status === 200) {
            vm.$store.dispatch("updateLoading", false);
            const token = response.data;
            const decoded = jwt_decode(token);
            vm.$cookies.set("Account", decoded.Account); // 登入者帳號
            vm.$cookies.set("userData", token, new Date(decoded.exp * 1000));
            vm.$cookies.set(
              "userId",
              decoded.sub,
              new Date(decoded.exp * 1000)
            );
            vm.$cookies.set(
              "userName",
              decoded.UserName,
              new Date(decoded.exp * 1000)
            );
            vm.$router.push({ path: "/healthReport" });
          }
        })
        .catch(() => {
          vm.$store.dispatch("updateLoading", false);
        });
    },

    // 忘記密碼 Modal
    forgetPassword() {
      this.account = null;
      this.password = null;
      this.confirmPassword = null;
      this.code = null;
      this.$refs.forgetPassword.reset();
      this.$refs.changePassword.reset();
      $("#forgetPasswordModal").modal("show");
    },

    // 發送驗證碼 API
    sendCode() {
      const vm = this;
      const api = `${window.BaseUrl.api}/auth/user/ForgetPassword/${vm.account}`;

      const data = {
        account: vm.account,
      };

      $("#forgetPasswordModal").modal("hide");
      vm.$store.dispatch("updateLoading", true);
      vm.$http
        .post(api, data)
        .then((response) => {
          if (response.status === 200) {
            vm.$store.dispatch("updateLoading", false);
            $("#changePasswordModal").modal("show");
          }
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          vm.$notify({
            title: "錯誤",
            message: error.response.data,
            type: "error",
            duration: 3500,
          });
        });
    },

    // 變更密碼 API
    changePassword() {
      const vm = this;
      const api = `${window.BaseUrl.api}/auth/user/ChangePasswordByForgetPassword`;

      const data = {
        Account: vm.account,
        Password: vm.password,
        Code: vm.code,
      };

      $("#changePasswordModal").modal("hide");
      vm.$store.dispatch("updateLoading", true);
      vm.$http
        .post(api, data)
        .then((response) => {
          if (response.status === 200) {
            vm.$store.dispatch("updateLoading", false);
            vm.$notify({
              title: "成功",
              message: "密碼變更成功",
              type: "success",
              duration: 2500,
            });
          }
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          vm.$notify({
            title: "錯誤",
            message: error.response.data,
            type: "error",
            duration: 3500,
          });
        });
    },
  },

  // beforeRouteEnter(to, from, next) {
  //   // 在這裡沒有 this，不過可以在 next 裡面使用 vm 指向元件實體(就是 Login 本身)。
  //   // next 會直接導向登入頁面，所以不用調整
  //   next((vm) => {
  //     if (vm.$cookies.get("userId")) {
  //       next({ path: "/manager/index" });
  //     }
  //   });
  // },

  destroyed() {
    this.$notify.close();
  },
};
</script>

<style lang="scss" scoped>
.login-page {
  height: 100vh;
  position: relative;
  background-image: url("https://cdn.pixabay.com/photo/2016/07/22/16/29/fog-1535201_960_720.jpg");
  background-size: cover;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2;
  }
  @media only screen and (min-width: 768px) {
    .login-wrap {
      background-color: #fff;
      position: absolute;
      display: flex;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 3;
      width: 100%;
      max-width: 960px;
      &::before {
        display: block;
        content: "";
        width: 50%;
        transition: width 0.3s;
      }

      .cover-wrap {
        width: 90%;
        max-width: 960px;
        width: 50%;
        background-image: url("~@/assets/img/login/background.png");
        background-position: bottom;
        color: #fff;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 50%;
        transition: left 0.3s;
        overflow: hidden;
        z-index: 5;

        &:after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background: rgba(16, 63, 108, 0.56);
          z-index: 1;
        }

        .cover-inner {
          position: relative;
          z-index: 2;
          white-space: nowrap;
          .title {
            font-size: 2.5em;
            margin-bottom: 72px;
          }

          .sub-title {
            font-size: 1em;
            letter-spacing: 1px;
            line-height: 26px;
            margin-bottom: 10px;
          }

          .sign-btn {
            border: 1px solid #fff;
            color: #fff;
            text-align: center;
            margin-top: 10px;
            display: inline-block;
            border-radius: 25px;
            height: calc(1.5em + 0.75rem + 2px);
            padding: 0.375rem 0.75rem;
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.5;
            cursor: pointer;
          }

          .sign-text-wrap,
          .login-text-wrap {
            display: inline-block;
            white-space: initial;
            width: 100%;
            padding: 30px;
            vertical-align: top;
          }
        }
      }

      .login-form-wrap {
        width: 50%;
        white-space: nowrap;
        overflow: hidden;
        .login-form-inner {
          transition: all 0.3s;
          .sign-form,
          .login-form {
            display: inline-block;
            white-space: initial;
            width: 100%;
            padding: 60px;
            vertical-align: top;
          }
        }

        .form-title {
          font-weight: bold;
          text-align: center;
          margin-bottom: 40px;
        }
        label {
          font-size: 0.8em;
          opacity: 0.5;
          display: none;
        }
        input {
          border-radius: 25px;
          margin-bottom: 20px;
          background-color: #e5edf6;
          border: none;
          height: calc(1.5em + 0.75rem + 2px);
          padding: 0.375rem 0.75rem;
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 1.5;
          display: block;
          width: 100%;
        }
        .login-btn {
          background-color: #164064;
          display: block;
          width: 100%;
          border-radius: 25px;
          color: #fff;
          border: none;
          height: calc(1.5em + 0.75rem + 2px);
          padding: 0.375rem 0.75rem;
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 1.5;
        }
      }

      &.is-sign {
        &:before {
          width: 0;
        }
        .cover-wrap {
          left: 50%;
          .cover-inner {
            transform: translateX(-100%);
          }
        }

        .login-form-inner {
          transform: translateX(-100%);
        }
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .login-wrap {
      width: 95%;
      margin: 0 auto;
      position: relative;
      z-index: 3;
      padding-top: 10px;

      .cover-wrap {
        background-image: url("https://cdn.pixabay.com/photo/2016/07/22/16/29/fog-1535201_960_720.jpg");
        background-position: bottom;
        color: #fff;
        overflow: hidden;
        position: relative;

        &:after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background: rgba(16, 63, 108, 0.56);
          z-index: 1;
        }

        .cover-inner {
          position: relative;
          z-index: 2;
          white-space: nowrap;
          transition: all 0.2s;
          .title {
            text-align: center;
            font-size: 1.5em;
            margin-bottom: 30px;
          }
          .sub-title {
            text-align: center;
            font-size: 0.9em;
            margin-bottom: 20px;
          }
          .sign-btn {
            border: 1px solid #fff;
            color: #fff;
            text-align: center;
            // margin-top: 10px;
            // display: inline-block;
            border-radius: 25px;
            // height: calc(1.5em + 0.75rem + 2px);
            padding: 0.375rem 0.75rem;
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.5;
            cursor: pointer;
          }

          .sign-text-wrap,
          .login-text-wrap {
            display: inline-block;
            white-space: initial;
            width: 100%;
            padding: 30px;
            vertical-align: top;
          }
        }
      }

      .login-form-wrap {
        overflow: hidden;
        background-color: #fff;
        .login-form-inner {
          transition: all 0.2s;

          white-space: nowrap;
          .sign-form,
          .login-form {
            display: inline-block;
            white-space: initial;
            padding: 30px;
            vertical-align: top;
            width: 100%;
          }
        }

        .form-title {
          font-weight: bold;
          text-align: center;
          margin-bottom: 20px;
        }
        label {
          font-size: 0.8em;
          opacity: 0.5;
          display: none;
        }
        input {
          border-radius: 25px;
          margin-bottom: 20px;
          background-color: #e5edf6;
          border: none;
          height: calc(1.5em + 0.75rem + 2px);
          padding: 0.375rem 0.75rem;
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 1.5;
          display: block;
          width: 100%;
        }
        .login-btn {
          background-color: #164064;
          display: block;
          width: 100%;
          border-radius: 25px;
          color: #fff;
          border: none;
          height: calc(1.5em + 0.75rem + 2px);
          padding: 0.375rem 0.75rem;
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 1.5;
        }
      }

      &.is-sign {
        .cover-inner {
          transform: translateX(-100%);
        }
        .login-form-inner {
          transform: translateX(-100%);
        }
      }
    }
  }
}
#forgetPasswordModal {
  .modal-content {
    border: none;
    -webkit-box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    position: relative;
    .modal-header {
      display: block;
      span {
        padding: 10px 0;
        font-size: 1.2em;
        font-weight: lighter;
        letter-spacing: 1px;
        display: block;
        position: relative;
      }
    }
    .modal-body {
      padding: 40px;
      .form-group {
        label {
          display: block;
          font-size: 0.9em;
          margin-bottom: 5px;
        }
        input {
          width: 100%;
          border: none;
          background-color: #f3f4ff;
          line-height: 30px;
        }
      }
    }
  }
}

#changePasswordModal {
  .modal-content {
    border: none;
    -webkit-box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    position: relative;
    .modal-header {
      display: block;
      span {
        padding: 10px 0;
        font-size: 1.2em;
        font-weight: lighter;
        letter-spacing: 1px;
        display: block;
        position: relative;
      }
    }
    .modal-body {
      padding: 40px;
      .form-group {
        label {
          display: block;
          font-size: 0.9em;
          margin-bottom: 5px;
        }
        input {
          width: 100%;
          border: none;
          background-color: #f3f4ff;
          line-height: 30px;
        }
      }
    }
  }
}
</style>
