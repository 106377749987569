<template>
  <div class="logo-container">
    <img :src="logo" alt="" />
  </div>
</template>

<script>
export default {
  name: "Logo",
  data() {
    return {
      logo: "",
    };
  },
  methods: {
    getLogo() {
      const vm = this;
      const api = `${window.BaseUrl.api}/BasicInformation/companyLogo/light`;

      vm.$http
        .get(api)
        .then((response) => {
          if (response.status === 200) {
            this.logo = response.data;
          }
        })
        .catch((error) => {
          vm.$notify({
            title: "取得公司Logo失敗",
            message: error.response.data,
            type: "error",
            duration: 3500,
          });
        });
    },
  },
  created() {
    // this.getLogo();
  },
};
</script>

<style lang="scss" scoped>
.logo-container {
  img {
    height: 40px;
    object-fit: contain;
  }
}
</style>
